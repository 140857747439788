import React from 'react'
import {BrowserRouter, Route} from "react-router-dom"

import Footer from '../components/Footer'
import FormCreation from './FormCreation'
import FormList from './FormList'
import ListSettings from './ListSettings'

export default function KazList(props) {

    const {listId} = props.match.params;

    return (
        <div>
            <BrowserRouter>
                <div>
                    {`List id : ${listId}`}
                </div>
                <Route exact path='/lists/:listId/new' component={FormCreation}/>
                <Route exact path='/lists/:listId/forms' component={FormList}/>
                <Route exact path='/lists/:listId/settings' component={ListSettings}/>
                <Footer/>
            </BrowserRouter>
        </div>
    )
}
