import React from 'react'
import {makeStyles} from '@material-ui/core'
import {useTheme} from '@material-ui/core/styles'
import {Paper, Typography} from '@material-ui/core'
import {Formik} from 'formik'

import Form from '../components/Form'

const useStyles = makeStyles(theme => ({
    paper: {
      marginTop: theme.spacing(1),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: theme.spacing(1)
    },
    container: {
      maxWidth: "200px"
    }
   }));

export default function ListSettings() {
    const theme = useTheme()
    const classes = useStyles(theme)
    return (
        <div>
            <Paper elevation={3} className={classes.paper}>
                <Typography variant="body1">
                    A remplir une bonne fois pour toute
                </Typography>
             <Formik render={Form}/>
            </Paper>
        </div>
    )
}
