import covid from '../assets/img/covid.png'
import chantier from '../assets/img/chantier.png'
import cameraIcon from '../assets/icon/camera.svg'
import checkIcon from '../assets/icon/check.svg'

const lists = [
    { 
        id:1,
        type: "covid",
        icon: checkIcon,
        title: "Attestations Bene",
        description: "Attestations de sortie Covid",
        image: covid
    },
    { 
        id:2,
        type: "observation",
        icon: cameraIcon,
        title: "Sécurité",
        description: "Problèmes de sécurité rencontrés sur chantier",
        image: chantier
    },
    { 
        id:3,
        type: "covid",
        title: "Attestations Antoine",
        description: "Attestation Covid",
        image: covid,
        icon: checkIcon
    }
]
export default lists