import {createMuiTheme} from '@material-ui/core/styles'

const kazBrown="#C9BB9D"
const kazBrownLight ="#C9BB9E"
const kazBrownDark ="#61564D"

export default createMuiTheme({
    palette:{
        common: {
            brown:`${kazBrown}`,
        },
        primary : {
            main: `${kazBrown}`
        }
    }
})