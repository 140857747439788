import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

const config = {
    apiKey: "AIzaSyCxyxa_g3uMl3CcDsklDvwwJoTcX-q1hE8",
    authDomain: "kazuzak-mobile.firebaseapp.com",
    databaseURL: "https://kazuzak-mobile.firebaseio.com",
    projectId: "kazuzak-mobile",
    storageBucket: "kazuzak-mobile.appspot.com",
    messagingSenderId: "790906126041",
    appId: "1:790906126041:web:083d61d01d06cd89f208c8",
    measurementId: "G-QKBTTS5WDZ"
  };

  
firebase.initializeApp(config);

export const auth = firebase.auth();
export const firestore = firebase.firestore();

const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({prompt:"select_account"});
export const signInWithGoogle = () => auth.signInWithPopup(provider);

export const createUserProfileDocument = async (userAuth,additionalData) => {
    if(!userAuth){
        return
    }
    const userRef = firestore.doc(`users/${userAuth.uid}`);
    const snapshot = await userRef.get();

    if(!snapshot.exists){
        const {displayName, email} = userAuth
        const createdAt = new Date()
        try {
            await userRef.set({
                displayName,
                email,
                createdAt,
                ...additionalData
            })
        } catch (error) {
           console.log("fail creating user", error) 
        }
    }
    return userRef
}

export default firebase;
