import React from 'react'
import {Button, makeStyles, useTheme} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    root: {
        position: "relative"
    },
    buttonContainer:{
        width: '100vw',
        position: "fixed",
        bottom: theme.spacing(10),
        display: 'flex',
        justifyContent: 'center'
    }
}))

export default function FormCreation() {

    const theme = useTheme();
    const classes = useStyles(theme);

    return (
        <div className={classes.root}>
            <div className={classes.buttonContainer}>
                <Button
                    variant="contained"
                    size="large"
                >
                    Générer le document 
                </Button>
            </div>
        </div>
    )
}
