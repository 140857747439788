import React, {useState, useEffect} from 'react';
import {BrowserRouter, Route, useLocation} from 'react-router-dom';
import {isEqual} from "lodash"

import {makeStyles} from '@material-ui/core'
import {ThemeProvider} from '@material-ui/styles'
import theme from './Theme.js'
import Header from './components/Header'
import KazList from './container/KazList'
import Home from './container/Home'
import Test from './container/Test.js'
import {auth, createUserProfileDocument} from '../firebase/firebase.utils'

import {arrayToObject} from '../utils/utils'

import listsData from '../data/lists'

const useStyles = makeStyles({
  root: { 
    padding: 0,
    margin: 0
  }
})

function App() {
  
  const classes = useStyles()
  
  const [currentUser,setCurrentUser] = useState()

  useEffect(()=> {
    const unsubscribeFromAuth = auth.onAuthStateChanged(async userAuth => {
      if (userAuth){
        const userRef = await createUserProfileDocument(userAuth)
        userRef.onSnapshot(snapshot => {
          const newUser = {id:snapshot.id,...snapshot.data()}
          if(!isEqual(currentUser,newUser)) {
            setCurrentUser(newUser)
          }
        })
      } else {
        setCurrentUser(userAuth)
      }
    })
    console.log(currentUser)
    return ()=>unsubscribeFromAuth()
  },[currentUser])

  return (
    <React.Fragment >
      <ThemeProvider theme={theme}>
        <div className = {classes.root}>
          <BrowserRouter>
            <Header lists={arrayToObject(listsData)} currentUser={currentUser}/>
            <Route path="/" exact component={Home}/>
            <Route path="/lists/:listId" component={KazList}/>
            <Route path="/test" component={Test}/>
          </BrowserRouter>
        </div>
      </ThemeProvider>
    </React.Fragment>
  );
}

export default App;
