import React from 'react'
import {Button} from '@material-ui/core'

import {signInWithGoogle} from '../../firebase/firebase.utils'

export default function SignIn() {
    return (
        <div>
            <Button onClick={signInWithGoogle}> Sign In With Google</Button>   
        </div>
    )
}
