import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import {Button,Icon,Typography} from '@material-ui/core';
import {Link} from "react-router-dom"
import {useTheme} from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  titleContainer:{
      display: 'flex',
      alignItems: 'center'
  },
  title:{
      marginLeft: theme.spacing(2)
  }
}));

export default function ListCard({id,type,title, description,image,icon}) {
    const theme = useTheme()
    const classes = useStyles(theme);

  return (
    <Card className={classes.root}>
      <CardActionArea component={Link} to={`lists/${id}`}>
        <CardMedia
          component="img"
          alt="Contemplative Reptile"
          height="140"
          image={image}
          title="Contemplative Reptile"
        />
        <CardContent>
          <div className={classes.titleContainer}>
            <Icon> <img src={icon} height={25} width={25}/></Icon>
            <Typography className={classes.title} variant="h5" component="h2">
                {title}
            </Typography>
          </div>
          <Typography variant="body2" color="textSecondary" component="p">
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" color="primary">
          Share
        </Button>
        <Button size="small" color="primary">
          Learn More
        </Button>
      </CardActions>
    </Card>
  );
}