import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {BottomNavigation,BottomNavigationAction} from '@material-ui/core';
import {NoteAdd, List, Settings} from '@material-ui/icons';
import {Link, useLocation} from 'react-router-dom'

const useStyles = makeStyles({
  root: {
    position: 'fixed',
    bottom:0,
    width:'100vw',
    padding:0,
    margin:0
  },
});

export default function Footer() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const location = useLocation()


  return (
  
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      showLabels
      className={classes.root}
    >
      <BottomNavigationAction 
        label="Liste" 
        icon={<List/>}
        component={Link}
        to='/lists/:listId/forms' 
      />
      <BottomNavigationAction 
        label="Formulaire" 
        icon={<NoteAdd/>}
        component={Link}
        to='/lists/:listId/new'
      />
      <BottomNavigationAction 
        label="Paramètres" 
        icon={<Settings/>} 
        component={Link}
        to='/lists/:listId/settings'
      />
    </BottomNavigation>
  );
}