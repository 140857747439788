import React from 'react'
import Camera from './Camera'
import SignIn from '../components/SignIn'

export default function Test() {
    return (
        <div>
            <SignIn/>
        </div>
    )
}
