import React from 'react'

import {makeStyles} from '@material-ui/core'
import {Button, AppBar,Toolbar, IconButton, List, ListItem, Typography } from '@material-ui/core'
import {SwipeableDrawer, useScrollTrigger} from '@material-ui/core'
import {Menu as MenuIcon, ArrowBack} from "@material-ui/icons"
import {useTheme} from "@material-ui/core/styles"
import {useLocation, Link} from 'react-router-dom'
import {auth} from '../../firebase/firebase.utils'

const useStyles = makeStyles(theme => ({
    toolbarMargin: {
        ...theme.mixins.toolbar}
    ,
    buttonContainer: {
        '&:hover': {
            backgroundColor:'transparent'
        }
    },
    signInContainer:{
        marginLeft: 'auto'
    }
}))

const menuItems = [
    {id:1, name: "Contact", link: '/contact'},
    {id:2, name: "Paramètres", link: '/settings'},
    {id:3, name: "Test", link: '/test'}
]

function ElevationScroll(props) {
    const { children } = props;

    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 0,
    });
  
    return React.cloneElement(children, {
      elevation: trigger ? 4 : 0,
    });
  }

export default function Header(props) {

    const {lists, currentUser} = props
    const location = useLocation();
    const theme = useTheme()
    const classes = useStyles(theme);
    const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

    const [openDrawer, setOpenDrawer] = React.useState(false)
    const [pathRoot,pathLists,pathListId]=location.pathname.split("/")

    // components
    const menuList = (
        <List>
            {menuItems.map(({id,name,link}) => (
                <ListItem
                    key={id}
                    component={Link}
                    to={link}
                >
                    {name}
                </ListItem>
            ))}
        </List>
    )

    const drawer = (
        <SwipeableDrawer 
            disableBackdropTransition={!iOS} 
            disableDiscovery={iOS}
            open={openDrawer}
            onOpen={()=>setOpenDrawer(true)}
            onClose={()=>setOpenDrawer(false)}
        >
            {menuList}
        </SwipeableDrawer>
    )

    const backButton = (
        <IconButton 
            component={Link} 
            to="/"
            edge="start" 
            color="inherit" 
            aria-label="menu"
            disableRipple
            className={classes.buttonContainer}
        >
            
            <ArrowBack/>
        </IconButton>
    )

    const menuButton = (
        <>
            <IconButton 
                edge="start" 
                color="inherit" 
                aria-label="menu"
                onClick={()=>setOpenDrawer(!openDrawer)}
                disableRipple
                className={classes.buttonContainer}
            >
                <MenuIcon />
            </IconButton>
            {drawer}
        </>
    )

    const signIn = (
        <div className={classes.signInContainer}>
            {currentUser 
            ? <Button onClick={()=>auth.signOut()}> Sign Out </Button>
            : <Button component={Link} to='/test'> Sign In </Button>
            }
        </div>
    )

    return (
        <>
        <ElevationScroll> 
            <AppBar>
                <Toolbar>
                    {location.pathname==='/' ? menuButton : backButton}
                    <Typography variant="h6">
                        {(location.pathname==='/' || location.pathname==='/test')
                        ? "Mes Listes" 
                        : lists[pathListId].title}
                    </Typography>
                    {signIn}
                </Toolbar>
            </AppBar>
        </ElevationScroll>
        <div className={classes.toolbarMargin}/>
        </>
    )
}
