export function arrayToObject(array){
    let object = {}
    array.forEach(item=>{
        object = {...object, [item.id]:item}
    })
    return object
}

export function isEqual(obj1, obj2){

    if(obj1 && obj2){
        const obj1Keys = Object.keys(obj1)
        const obj2Keys = Object.keys(obj2)

        if(obj1Keys.length !== obj2Keys.length){
            return false
        }

        for(let key of obj1Keys){
            if (obj1[key] !== obj2[key]){
                return false
            }
        }
        return true
    } else if (!obj1 && !obj2) {
        return true
    } else {
        return false
    }
}