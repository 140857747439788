import React from 'react'
import {TextField, Button} from "@material-ui/core"
import {makeStyles} from "@material-ui/core"
import {useTheme} from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
    root:{
        display:'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    field:{
        margin: theme.spacing(1)
    },
    buttonContainer:{
        marginLeft: 'auto',
        marginTop: theme.spacing(3)
    }
}))

export default function Form(){
    const theme = useTheme()
    const classes = useStyles(theme)
    return (
         <form className={classes.root}>
             <TextField
                id="firstName"
                name="firstName"
                variant="filled"
                label="Prénom"  
                size="small" 
                className={classes.field}
            />
             <TextField
                id="lastName"
                name="lastName"
                label="Nom"
                variant='filled'
                size="small" 
                className={classes.field}
            />
            <div className={classes.buttonContainer}>
                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                >
                    Enregistrer
                </Button>
            </div>
           
         </form>
    )
}
