import React from 'react'
import {makeStyles} from '@material-ui/core'
import {useTheme} from '@material-ui/core/styles'
import {Link} from "react-router-dom"

import ListCard from '../components/ListCard.js'

import lists from '../../data/lists'

const useStyles = makeStyles(theme => ({
    listsContainer: {
        display: "flex",
        margin: "auto",
        width:"90%",
        flexDirection: "column",
        justifyContent: "space-around",
        alignItems: "stretch"
    },
    divider: {
        height:theme.spacing(1)
    }
}))
export default function Home(){
    const theme = useTheme()
    const classes = useStyles(theme)
    return (
        <>
        <div className={classes.listsContainer}>
            {lists.map(({id,type,title,description,image,icon})=>(
                <div key={id}>
                    <div className={classes.divider}/>
                    <ListCard  
                        id={id}
                        title={title} 
                        description={description} 
                        image={image}
                        icon={icon}
                    />
                </div>
            ))}
        </div>
    
        </>
    )
}
